"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionBuilder = void 0;
const utils_1 = require("../utils");
const api_1 = require("../api");
const address_1 = require("../address");
const signer_1 = require("./signer");
class TransactionBuilder {
    static from(param0, param1, customFetch) {
        const nodeProvider = typeof param0 === 'string' ? new api_1.NodeProvider(param0, param1, customFetch) : param0;
        return new (class extends TransactionBuilder {
            get nodeProvider() {
                return nodeProvider;
            }
        })();
    }
    static validatePublicKey(params, publicKey, keyType) {
        const address = (0, address_1.addressFromPublicKey)(publicKey, keyType);
        if (address !== params.signerAddress) {
            throw new Error('Unmatched public key');
        }
    }
    async buildTransferTx(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { destinations, gasPrice, ...rest } = params;
        const data = {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            destinations: (0, signer_1.toApiDestinations)(destinations),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
        const response = await this.nodeProvider.transactions.postTransactionsBuild(data);
        return { ...response, gasPrice: (0, api_1.fromApiNumber256)(response.gasPrice) };
    }
    async buildDeployContractTx(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { initialAttoAlphAmount, initialTokenAmounts, issueTokenAmount, gasPrice, ...rest } = params;
        const data = {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            initialAttoAlphAmount: (0, api_1.toApiNumber256Optional)(initialAttoAlphAmount),
            initialTokenAmounts: (0, api_1.toApiTokens)(initialTokenAmounts),
            issueTokenAmount: (0, api_1.toApiNumber256Optional)(issueTokenAmount),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
        const response = await this.nodeProvider.contracts.postContractsUnsignedTxDeployContract(data);
        const contractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(response.contractAddress));
        return { ...response, groupIndex: response.fromGroup, contractId, gasPrice: (0, api_1.fromApiNumber256)(response.gasPrice) };
    }
    async buildExecuteScriptTx(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { attoAlphAmount, tokens, gasPrice, ...rest } = params;
        const data = {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            attoAlphAmount: (0, api_1.toApiNumber256Optional)(attoAlphAmount),
            tokens: (0, api_1.toApiTokens)(tokens),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
        const response = await this.nodeProvider.contracts.postContractsUnsignedTxExecuteScript(data);
        return { ...response, groupIndex: response.fromGroup, gasPrice: (0, api_1.fromApiNumber256)(response.gasPrice) };
    }
    async buildUnsignedTx(params) {
        const data = { unsignedTx: params.unsignedTx };
        const decoded = await this.nodeProvider.transactions.postTransactionsDecodeUnsignedTx(data);
        return {
            fromGroup: decoded.fromGroup,
            toGroup: decoded.toGroup,
            unsignedTx: params.unsignedTx,
            txId: decoded.unsignedTx.txId,
            gasAmount: decoded.unsignedTx.gasAmount,
            gasPrice: (0, api_1.fromApiNumber256)(decoded.unsignedTx.gasPrice)
        };
    }
}
exports.TransactionBuilder = TransactionBuilder;
